import './App.css';
import LogReg from './views/LogReg';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'; // Import BrowserRouter and Route
import Login from './components/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './components/Register';
import CreateAlbum from './components/CreateAlbum';
import Home from "./views/Home"
import Header from './components/Header';
import OneAlbum from './components/OneAlbum';
import EditAlbum from './components/EditAlbum';
import UserProfile from './components/UserProfile';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={LogReg} />
          <Route path="/Login" component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/album/new" component={CreateAlbum} />
          <Route path="/album/:id" component={OneAlbum} />
          <Route path="/album/edit/:id" component={EditAlbum} />
          <Route path="/user/profile/:id" component={UserProfile} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

