import React from 'react';
import axios from 'axios';
import {useHistory, Link} from 'react-router-dom';



const DeleteAlbum = (props) =>{

    const {id, deleteFilter} = props;
    const history = useHistory();

    const deleteHandler =(e) =>{
        axios.delete(`/api/albums/${id}`)
        .then((res)=>{
            if(deleteFilter){
                console.log(res.data);
                deleteFilter(id);
            }
            else{
                console.log(res.data);
                history.push("/home");
            }

            deleteFilter(id);
            console.log(res.data);
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    return(
        <button className="single-album-button" onClick={deleteHandler}>Delete</button>
    )
}

export default DeleteAlbum;
