import React from "react";
import Login from "../components/Login";
import Register from "../components/Register";

const LogReg = () => {
    return (
        <div>
            {/* <Login />
            <hr /> */}
            <Register />
        </div>
    );
};

export default LogReg;
